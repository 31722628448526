import { BrowserRouter as Router } from "react-router-dom";
import MainContent from "./mainComponent/MainContent";


function App() {

  return (
    <Router>
      <div >
        <MainContent />
      </div>
    </Router>
  );
}

export default App;
