// src/AdminPanel/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './login.css'

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    // Replace these with your actual credentials
    const adminUsername = 'admin';
    const adminPassword = 'Sudhansabarii';

    if (username === adminUsername && password === adminPassword) {
      onLogin(); // Call onLogin to set authentication state
      navigate('/admin-panel');
    } else {
      setError('Invalid credentials');
    }
  };

  return (
    <div className="login-page">
      <div  className="container mt-5 login-contianer" >
        <h1 className="mb-4">ADMIN Login</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="form-label">Username:</label>
            <input
              type="text"
              className="form-control"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Password:</label>
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">Login</button>
        </form>
        {error && <p className="text-danger mt-3">{error}</p>}
      </div>
    </div>
  );
};

export default Login;
