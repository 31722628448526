import React from 'react';
import './Product.css';
import IndividualIntervalsExample from './Carousel';
import productImage from '../images/372_sub-banner-voice.png'; 
import ProductList from './myProducts.jsx'


const Product = () => {
  return (
    <div className="product-page">
      <IndividualIntervalsExample />
      {/* <h1>Our Products</h1> */}
      <div className="product-list">
        {/* <div className="product-item">
          <img src={productImage} alt="Product" className="product-image" />
          <h2>Product 1</h2>
          <p className="product-description">This is a great product.</p>
          <p className="product-price">$19.99</p>
        </div>
        <div className="product-item">
          <img src={productImage} alt="Product" className="product-image" />
          <h2>Product 2</h2>
          <p className="product-description">This is another great product.</p>
          <p className="product-price">$29.99</p>
        </div> */}
        <ProductList/>
        {/* Add more products as needed */}
      </div>
    </div>
  );
};

export default Product;
