import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import image from "../images/logo.png";
import './OffcanvasExample.css'
import React, { useState, useEffect } from 'react';

function OffcanvasExample({ handleButtonClick }) {
    const [isSticky, setIsSticky] = useState(false);
    useEffect(() => {
    console.log('useEffect called'); 

    const handleScroll = () => {
      console.log('handleScroll called');
      console.log('scrollY:', window.scrollY);
      if (window.scrollY > 0) {
        setIsSticky(true);
        console.log('Sticky ON');
      } else {
        setIsSticky(false);
        console.log('Sticky OFF');
      }
    };

    window.addEventListener('scroll', handleScroll);
    console.log('Scroll event listener added'); 

    return () => {
      console.log('Cleanup');
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      {[ 'sm'].map((expand) => (
        <Navbar key={expand} expand={expand} className= {` bootstrap-navbar-onload ${isSticky ? 'sticky-navbar' : ''}`}>
            
          <Container fluid>
          <Navbar.Brand href="/">
            <img
              src={image}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="logo"
            />
          </Navbar.Brand>
          <Navbar.Brand href="/" className="d-none d-md-block">Sabari Communications</Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className="offcanvas-body"
            >
              <Offcanvas.Header closeButton >
                {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Sabai Communications
                  </Offcanvas.Title> */}
              </Offcanvas.Header>
              <Offcanvas.Body >
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="/product">Product</Nav.Link>
                  {/* <Nav.Link href="/about">About</Nav.Link> */}
                  <Nav.Link href="/contact">Contact</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default OffcanvasExample;