// src/components/Loading.js
import React from 'react';
import loadingImg from './loading.gif'
import './loading.css'

const Loading = () => {
  return (
    <div className="loading-bar" style={{ textAlign: 'center', padding: '50px' }}>
     
      <img src={loadingImg} alt="loading...." className='loading-bar-img' />
      {/* <div className="spinner-border" role="status">
        
         <span className="visually-hidden">Loading...</span>
      </div> */}
    </div>
  );
};

export default Loading;
