// src/AdminPanel/AdminPanel.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const AdminPanel = () => {
  return (
    <div className="container mt-5">
      <h1 className="mb-4">Admin Panel</h1>
      <div className="alert alert-info" role="alert">
        Welcome to the Admin Panel.
      </div>
    </div>
  );
};

export default AdminPanel;
