import React from 'react';
import './home.css';
import productImg from '../images/home-about.png'
import productIMG from '../images/home-support.png'
import OurCustomers from './ourCustomersLogo.jsx'
import ReviewGrid from './reviews.jsx'

function Home() {
  return (
    <div className="home">
      <div className="backroun-img">
        <div className="background-layer">
          <div className="content">
            <h3 className="company-name-content animate-up">Sabari Communications</h3>
            <p className="company-discripition animate-up">Your Partner in Advanced Voice, Security, and Connectivity Solutions.</p>
          </div>
        </div>
      </div>
      <div>
        
        {/* home-about-us */}
      <div  className="container-fluid home-about-us">
        <div className="container p-5 about-us-contiainer">
          <div className="row mb-4">
          <div className="col-md-12 text-center">
  <div className="About-us-tittle" style={{ marginBottom: '20px' }}>
    <h3 className="mb-3 About-title" >
      About Sabari Communications
    </h3>
    <span ></span>
  </div>
  <p className="lead aboutus-content" >
    Founded in 2011, Sabari Communications is dedicated to providing cutting-edge security and communication solutions tailored to meet the diverse needs of our clients. With a decade of experience, we have built a reputation for delivering reliable products and services, from advanced surveillance systems to seamless communication networks. Our mission is to empower individuals and businesses through innovative technology that enhances safety and drives productivity. Committed to customer satisfaction, we strive to offer personalized solutions that align with the unique requirements of each client, ensuring quality and excellence in every aspect of our service.
  </p>
</div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="card mb-4 product-support">
                <div className="card-body ">
                  <h4 className="card-title"> <span>Product</span></h4>
                  <div className="d-flex">
                    {/* <img src={productImg} alt="Product" className="img-fluid me-3" style={{ maxWidth: '150px' }} /> */}
                    <p className="card-text about-my-product" >
  <strong className='about-my-products'>CCTV Cameras</strong> <span className='about-product-discription'>Advanced surveillance solutions for enhanced security.</span><br/>
  <strong className='about-my-products'>Call Billing Software</strong><span className='about-product-discription'> Efficient management of call records and billing.</span><br/>
  <strong className='about-my-products'>Home Security Products</strong><span className='about-product-discription'> Comprehensive systems to protect your home.</span><br/>
  <strong className='about-my-products'>EPABX Telephones</strong> <span className='about-product-discription'>Reliable communication systems for businesses.</span><br/>
  <strong className='about-my-products'>Voice Loggers</strong> <span className='about-product-discription'>Secure recording solutions for quality assurance.</span><br/>
  <strong className='about-my-products'>Biometric Devices</strong> <span className='about-product-discription'>Cutting-edge technology for secure access control.</span><br/>
</p>

                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card mb-4 product-support">
                <div className="card-body">
                  <h4 className="card-title"> <span>Support</span></h4>
                  <div className="d-flex ">
                    {/* <img src={productIMG} alt="Support" className="img-fluid me-3" style={{ maxWidth: '150px' }} /> */}
                    <p className="card-text about-my-product">At Sabari Communications, we prioritize our customers by providing exceptional support and service.<br/>
                     Our knowledgeable team is available to assist you with any inquiries, technical issues, or product guidance.<br/>
                      We believe in building long-term relationships with our clients, ensuring they receive the help they need to maximize the benefits of our products.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>

      {/* customers */}
      <div className="our-customers-container">
          <div>
            <h2 className="our-customers-section-title">
              <span className="happycust">  Trusted by  worldwide organizations</span>
            </h2>
            <div className='our-customer-logo-container'>
              <OurCustomers/>
            </div>
          </div>
      </div>

      <ReviewGrid/>

      </div>
      
        
    </div>





  );
}

export default Home;
