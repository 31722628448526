import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import image from "../images/logo.png";
import './EnquiryForm.css';

function EnquiryForm({ onClose }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

  
    // Define the payload from the form state
    const payload = {
      name: name,
      contactNumber: email,
      email: mobile,
      message: message,
    };
  
    try {
      const response = await fetch('https://send-mail-sabari-communications.vercel.app/api', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const data = await response.json();
        alert('Message sent successfully!');
        console.log('Response:', data);
        // You can reset the form or handle a success state here
      } else {
        const error = await response.text();
        console.log('Failed to send message: ' + error);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      console.log('An error occurred. Please try again.');
    }
  };

  return (
    <div className="enquiry">
      <div className="enquiry-header">
        <div className="enquiry-header-content">
          <img src={image} width="50" height="40" alt="logo" />
          <div>Sabari Communications</div>
        </div>
      </div>
      <div className="contact-form">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="mobile">Mobile Number:</label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              placeholder="Your Mobile Number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              placeholder="Your Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </div>
          <button className="EnquirySubmitBtn" type="submit">
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth="2"
              viewBox="0 0 24 24"
              strokeLinecap="round"
              strokeLinejoin="round"
              focusable="false"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line x1="22" y1="2" x2="11" y2="13"></line>
              <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
            </svg>
          </button>
        </form>
      </div>
    </div>
  );
}

export default EnquiryForm;
