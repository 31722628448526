// src/components/Footer.js
import React from 'react';
import './Footer.css'; // Create a separate CSS file for styling if needed
import '@fortawesome/fontawesome-free/css/all.min.css';

const Footer = () => {
  return (
    <footer className="footer bg-dark text-white py-4">
      <div className="container">
        <div className="row">
          {/* Company Info */}
          <div className="col-md-4">
            <h5>About Us</h5>
            <p>
              Sabari Communications is dedicated to providing top-notch communication services. We aim for quality and customer satisfaction.
            </p>
          </div>

          {/* Quick Links */}
          <div className="col-md-4">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><a href="/" className="text-white">Home</a></li>
              <li><a href="/about" className="text-white">About</a></li>
              <li><a href="/Product" className="text-white">Product</a></li>
              <li><a href="/contact" className="text-white">Contact</a></li>
            </ul>
          </div>

          {/* Contact Info */}
          <div className="col-md-4">
            <h5>Contact Us</h5>
            <ul className="list-unstyled">
              <li> SabariCommunications,</li>
              <li><i className=" fas fa-map-marker-alt"></i> 8/13,Lokesh Nagar,thendral nagar east,</li>
              <li>thirumullaivoyal, Chennai-600062. </li>
              <li></li>
              <li><i className="fas fa-phone"></i>+91-9841082532 </li>
              <li><i className="fas fa-envelope"></i>kindmaniece07@gmail.com</li>
            </ul>
          </div>



          {/* Social Media Links */}
          {/* <div className="col-md-3">
            <h5>Follow Us</h5>
            <ul className="list-unstyled d-flex">
              <li><a href="#" className="text-white me-3"><i className="fab fa-facebook"></i></a></li>
              <li><a href="#" className="text-white me-3"><i className="fab fa-twitter"></i></a></li>
              <li><a href="#" className="text-white me-3"><i className="fab fa-instagram"></i></a></li>
              <li><a href="#" className="text-white"><i className="fab fa-linkedin"></i></a></li>
            </ul>
          </div> */}
        </div>
        {/* Copyright Section */}
        <div className="text-center mt-4">
          <p>&copy; 2024 Sabari Communications. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
